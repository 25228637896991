import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import PlaceSelect from "./PlaceSelect.tsx";
import useFormPlaceValidator from "../../../../../pages/searchResults/hooks/useFormPlaceValidator.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import getFormVersionClassNameAggregator from "../../../functions/getFormVersionClassNameAggregator.ts";
import {FormVersionType} from "../../../types/FormVersion.type.ts";

interface StationSelectorsProps {
    showIsValid?: boolean
    searchStrict?: boolean,
    showIsIncomplete?: boolean,
    data?: any,
    fromRef: HTMLInputElement,
    toRef: HTMLInputElement,
    dateRef: HTMLInputElement,
    isIncompleteDialog?: boolean
    formVersion: FormVersionType
}

function PlaceSelectors(props: StationSelectorsProps) {
    const {
        showIsValid = false,
        searchStrict,
        showIsIncomplete,
        data,
        fromRef,
        dateRef,
        toRef,
        isIncompleteDialog = false,
        formVersion
    } = props;

    const {
        pickUpLocation: pickUpLocationLabel,
        destinationLocation: destinationLocationLabel,
        from: fromLabel,
        to: toLabel,
    } = useTranslation('search.form') as Record<any, string>

    const {invalidDeparture, invalidDestination, areSame} = useFormPlaceValidator();
    const {
        fromPlace,
        toPlace,
    } = useAppSelector((state) => (state.form));

    const {v} = getFormVersionClassNameAggregator(formVersion);

    return (
        <>
            <div className={`gb--search${v()}__select gb--search${v()}__select--from`}>
                <PlaceSelect
                    direction='from'
                    name='departure'
                    label={pickUpLocationLabel}
                    placeholder={fromLabel}
                    showIsValid={showIsValid}
                    isValid={!invalidDeparture && !areSame}
                    searchStrict={searchStrict}
                    showIsIncomplete={showIsIncomplete && !!fromPlace && !fromPlace.placeId}
                    data={data}
                    inputRef={fromRef}
                    toRef={!toPlace?.placeId ? toRef : dateRef}
                    isIncompleteDialog={isIncompleteDialog}
                    formVersion={formVersion}
                />
            </div>
            <div className={`gb--search${v()}__select gb--search${v()}__select--to`}>
                <PlaceSelect
                    direction='to'
                    name='destination'
                    label={destinationLocationLabel}
                    placeholder={toLabel}
                    showIsValid={showIsValid}
                    isValid={!invalidDestination && !areSame}
                    searchStrict={searchStrict}
                    showIsIncomplete={showIsIncomplete && !!toPlace && !toPlace.placeId}
                    data={data}
                    inputRef={toRef}
                    toRef={!fromPlace?.placeId ? fromRef : dateRef}
                    isIncompleteDialog={isIncompleteDialog}
                    formVersion={formVersion}
                />
            </div>
        </>
    );
}

export default PlaceSelectors;
import IconLocationMarker from "../../../../../components/generic/icons/IconLocationMarker.tsx";
import useMobileStationInput from "../hooks/useMobileStationInput.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import FadingError from "../../../../../features/errors/components/FadingError.tsx";
import ValidPlaceIndicator from "./ValidPlaceIndicator.tsx";
import PlaceSwitchButton from "./PlaceSwitchButton.tsx";
import getFormVersionClassNameAggregator from "../../../functions/getFormVersionClassNameAggregator.ts";
import {FormVersionType} from "../../../types/FormVersion.type.ts";

interface MobileStationInputButtonProps {
    direction: "departure" | "destination",
    showIsValid?: boolean
    isValid?: boolean,
    showIsIncomplete?: boolean,
    formVersion: FormVersionType
}

function MobilePlaceInputButton(props: MobileStationInputButtonProps) {
    const {
        direction,
        showIsValid,
        isValid,
        showIsIncomplete,
        formVersion
    } = props

    const {
        className,
        onClick,
        directionLabel,
        place,
        direction: directionKey,
        errorMessage,
        error,
        submitTimestamp,
    } = useMobileStationInput(direction, formVersion)

    const cityAirportOrStation = useTranslation(`search.form.${directionKey}`) as string

    const {v} = getFormVersionClassNameAggregator(formVersion);

    return (
        <div
            className={`${className} ${showIsIncomplete ? `gb--search${v()}__select--incomplete-address-border` : ""}`}
            onClick={onClick}
            role="button"
        >
            {showIsIncomplete && (
                <div className={`gb--search${v()}__select--incomplete-address-container`}>
                    <div className={`gb--search${v()}__select--incomplete-address`}>
                        Update address
                    </div>
                </div>
            )}
            <span className={`gb--search${v()}__select--label gb--emphasize gb--emphasize-default`}>
                {directionLabel}
            </span>
            <IconLocationMarker
                className={`gb--search${v()}__select--icon`}
                height="14"
            />
            <input
                placeholder={cityAirportOrStation}
                readOnly
                type="text"
                value={place?.placeName ?? ''}
            />
            {showIsValid && (
                <ValidPlaceIndicator
                    isValid={isValid!}
                />
            )}
            <FadingError
                isError={error}
                showError={submitTimestamp}
                errorMessageTranslationKey={errorMessage}
            />
            {direction === 'departure' && (
                <PlaceSwitchButton/>
            )}
        </div>
    )
}

export default MobilePlaceInputButton
import {FormVersionType} from "../types/FormVersion.type.ts";
import HeroBackgroundMain from "../features/hero/components/HeroBackgroundMain.tsx";

interface FormBackgroundProps {
    formVersion?: FormVersionType
}

function FormBackground({formVersion = "1"}: FormBackgroundProps) {
    if (formVersion === "2") {
        return (
            <></>
        )
    }

    return (
        <HeroBackgroundMain/>
    )
}

export default FormBackground
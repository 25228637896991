import useReturnDateSelectHandlers from "../hooks/useReturnDateSelectHandlers.ts";
import IconCalendar from "../../../../../components/generic/icons/IconCalendar.tsx";
import CustomDropdown from "../../../../../components/generic/CustomDropdown.tsx";
import DateTimeSelect from "../../../../../features/dateTime/components/DateTimeSelect.tsx";
import IconTimesCircle from "../../../../../components/generic/icons/IconTimesCircle.tsx";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import {setReturnDate} from "../../../../../reducers/formSlice.ts";
import {add} from "date-fns";
import {useMatches} from "react-router";
import getFormVersionClassNameAggregator from "../../../functions/getFormVersionClassNameAggregator.ts";
import {FormVersionType} from "../../../types/FormVersion.type.ts";

function ReturnDateTimeField({formVersion}: {formVersion: FormVersionType}) {
    const {
        departure,
        setDeparture,
        isDropdownOpen,
        setIsDropdownOpen,
        dateDisplay,
        dateLabel,
        handleToggle,
        departureDate,
        departingOnLabel,
        selectReturnDate,
        activateMobileOverlayDatePicker,
    } = useReturnDateSelectHandlers()

    const matches = useMatches()
    const isRemoveable = matches[matches.length - 1].id !== 'booking-info'

    const {
        returnDate
    } = useAppSelector((state) => state?.form)

    const dispatch = useAppDispatch()

    const handleRemove = () => {
        dispatch(setReturnDate(null))
    }
    
    const {v} = getFormVersionClassNameAggregator(formVersion);

    return (
        <div className={`gb--search${v()}__select--datepicker-input`} onClick={activateMobileOverlayDatePicker}>
            <CustomDropdown
                field=
                    {
                        <div className={'return-datepicker'}>
                            <IconCalendar className={`gb--search${v()}__select--icon`}/>
                            <input
                                type="text"
                                name="passengers"
                                className="gb--input-standalone"
                                placeholder={`${dateLabel}`}
                                value={dateDisplay}
                                readOnly
                            />
                            {returnDate && isRemoveable  && (
                                <div
                                    onClick={handleRemove}
                                >
                                    <IconTimesCircle
                                        width={'18'}
                                        height={'18'}
                                        fill={'#f2f5f7'}
                                        backgroundFill={'#ff8c77'}
                                        className={'return-datepicker-remove'}
                                    />
                                </div>
                            )}
                        </div>
                    }
                isOpen={isDropdownOpen}
                setIsOpen={handleToggle}
                required
                childrenWrapperClass={'gb--select-dropdown-time'}
            >
                <DateTimeSelect
                    selectedDate={departure}
                    selectedTime={departure}
                    changeDate={(value: Date) => {
                        setDeparture( new Date(value).toISOString())
                    }}
                    changeTime={(value: Date) => {
                        setDeparture( new Date(value).toISOString())
                    }}
                    submitSelect={selectReturnDate}
                    dateLabel={'Date'}
                    handleClose={() => {
                        setIsDropdownOpen(false)
                    }}
                    isClosable={true}
                    timeLabel={'Time'}
                    minDateTime={departureDate ? add(new Date(departureDate), {hours: 1}) : add(new Date(), {hours: 1})}
                />
            </CustomDropdown>
            <span
                className={`gb--search${v()}__select--label gb--emphasize gb--emphasize-default`}
            >
                {departingOnLabel}
            </span>
        </div>
    );
}

export default ReturnDateTimeField;
import VehicleCardsPortal from "./components/VehicleCardsPortal";
import {FAQ} from "./components/FAQ.tsx";
import StsPageHeader from "./features/header/StsPageHeader.tsx";
import SearchForm from "../../../layout/form/components/SearchForm.tsx";

const StationToStationPage = () => {
    return (
        <>
            {document.getElementById('station-to-station') ? (
                <StsPageHeader/>
            ) : (
                <SearchForm formVersion={"1"}/>
            )}
            {document.getElementById('vehicle-group-portal') && (
                <VehicleCardsPortal/>
            )}
            {document.getElementById('faq-portal') && (
                <FAQ/>
            )}
        </>
    )
}

export default StationToStationPage;
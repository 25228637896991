import IncompleteDialogHeader from "../../../../../searchResults/features/incompleteDialog/components/IncompleteDialogHeader.tsx";
import PlaceSelectors from "../../../../../../layout/form/features/stations/components/PlaceSelectors.tsx";
import DateTimeIncomplete from "../../../../../searchResults/features/incompleteDialog/components/DateTimeIncomplete.tsx";
import DateTimeField from "../../../../../../layout/form/features/dates/components/DateTimeField.tsx";
import Button from "../../../../../../components/generic/Button.tsx";
import Translation from "../../../../../../features/translation/components/Translation.tsx";
import FullScreenLoader from "../../../../../../components/generic/loaders/FullScreenLoader.tsx";
import {
    useGetIncompleteSearchFormDialogContent
} from "../../../../../searchResults/features/incompleteDialog/functions/getIncompleteSearchFormDialogContent.ts";
import { useToggle } from "@getbybus/gbb-green";
import useSubmitSearch from "../../../../../../layout/form/hooks/useSubmitSearch.ts";
import { useAppSelector } from "../../../../../../hooks/selector/useAppSelector.ts";

export const StaticIncompleteModalBody = () => {
    const { departureDate } = useAppSelector((state) => state?.form);
    const { value, onChange, set } = useToggle(false);
    const { submitForm, isFetching } = useSubmitSearch();
    const { title, description } = useGetIncompleteSearchFormDialogContent(false, false);

    if (isFetching) {
        return (
            <div>
                <FullScreenLoader/>
                <Translation translationKey={"booking.search.incompleteLoader"}/>
            </div>
        );
    }

    return (
        <div className={'gb--vehicle__incomplete'}>
            <IncompleteDialogHeader title={title} description={description}/>
            {!value && (
                <>
                    <PlaceSelectors
                        showIsValid
                        searchStrict
                        isIncompleteDialog
                        formVersion={'1'}
                    />
                </>
            )}
            <div className="search__select search__select--datepicker gb--row gb--row-middle">
                {value ? (
                    <DateTimeIncomplete closeClick={onChange}/>
                ) : (
                    <DateTimeField
                        formVersion={'1'}
                        isValid={!!departureDate}
                        showIsValid={true}
                        additionalOnClick={onChange}
                    />
                )}
            </div>
            {!value && (
                <Button
                    onClick={submitForm}
                    className='blue-darker'
                    type="button"
                    width="full"
                    size="large"
                    additionalClasses="incomplete-search-form-overlay--content__button-confirm btn--blue-darker"
                >
                    <Translation translationKey="booking.search.confirm"/>
                </Button>
            )}
        </div>
    );
};
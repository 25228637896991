import {Outlet, useMatches} from "react-router";
import MainContent from "../../mainContent/MainContent.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import BookingNavigationItemList from "./BookingNavigationItemList.tsx";
import useNavigateToHome from "../../../routing/navigation/useNavigateToHome.ts";
import ExpiredResults from "./ExpiredResults.tsx";
import {allRouteIds} from "../../../routing/constants/allRouteIds.ts";
import SearchForm from "../../form/components/SearchForm.tsx";
import useIsDesktop from "../../../hooks/selector/useIsDesktop.ts";

function BookingNavigation() {
    const matches = useMatches();
    const {navigateToHome} = useNavigateToHome(true)

    const {
        id
    } = useAppSelector((state) => state?.vehicleType);

    const {
        isBooked
    } = useAppSelector((state) => state?.bookingForm)

    const isBookingForm = matches[matches.length - 1].id === allRouteIds.bookingInfo;
    const isBooking = matches.find((match) => match.id === "booking");
    const isSmallDisplay = useIsDesktop();

    const showForm = !(isBooking && !isSmallDisplay) && !isBookingForm;


    if ((matches[matches.length - 1]?.id === 'successful' && !id) || (matches[matches.length - 1]?.id === 'booking-info' && isBooked)) {
        navigateToHome()
    }

    return (
        <>
            {showForm && <SearchForm/>}
            <MainContent className="booking-container">
                <ExpiredResults/>
                <BookingNavigationItemList/>
                <Outlet/>
            </MainContent>
        </>

    );
}


export default BookingNavigation;
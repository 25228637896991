import LoaderButton from "../../../../components/generic/loaders/LoaderButton.tsx";
import Translation from "../../../../features/translation/components/Translation.tsx";
import {FormVersionType} from "../../types/FormVersion.type.ts";
import getFormVersionClassNameAggregator from "../../functions/getFormVersionClassNameAggregator.ts";

interface SubmitButtonProps {
    submitForm: () => void,
    isFetching: boolean,
    formVersion: FormVersionType,
}

function SubmitButton(props: SubmitButtonProps) {
    const {
        isFetching,
        submitForm,
        formVersion
    } = props;

    const {v} = getFormVersionClassNameAggregator(formVersion);

    if (isFetching) {
      return (
            <LoaderButton
                style={{}}
                className={`btn btn--green gb--search${v()}__select--action`}
            />
      )
    }

    return (
        <button
            style={{margin: 0}}
            className={`btn btn--green gb--search${v()}__select--action`}
            onClick={submitForm}
        >
            <Translation
                translationKey='search.form.button'
                styleText="upperCase"
            />
        </button>
    )
};

export default SubmitButton;
import LocationToLocationCountryImageType from "../types/LocationToLocationCountryImage.type.ts";


type LocationToLocationBackgroundImageProps = {
    imagePaths: LocationToLocationCountryImageType,
    setImageFailed: (boolean) => void
}

function LocationToLocationBackgroundImage({imagePaths, setImageFailed}: LocationToLocationBackgroundImageProps) {
    const {
        pathMobile,
        pathTablet,
        pathWindow,
    } = imagePaths

    return (
        <div className="gb--search-2-background">
            <picture>
                <source
                    media="(min-width: 680px)"
                    type="image/jpg"
                    srcSet={pathTablet}
                    onError={() => setImageFailed(true)}
                />
                <source
                    media="(min-width: 992px)"
                    type="image/jpg"
                    srcSet={pathWindow}
                    onError={() => setImageFailed(true)}
                />
                <img
                    typeof="image/jpg"
                    src={pathMobile}
                    alt="heading"
                    onError={() => setImageFailed(true)}
                />
            </picture>
            <div className="gb--search-2-background_overlay"/>
        </div>
    )
}

export default LocationToLocationBackgroundImage
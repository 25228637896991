import CustomDropdown from "../../../../../components/generic/CustomDropdown.tsx";
import PassengerPickerOptions from "./PassengerPickerOptions.tsx";
import PassengerPickerInput from "./PassengerPickerInput.tsx";
import useTogglePassengerPicker from "../hooks/useTogglePassengerPicker.ts";
import {useToggle} from "../../../../../hooks/dispatch/useToggle.ts";
import Translation from "../../../../../features/translation/components/Translation.tsx";
import FadingError from "../../../../../features/errors/components/FadingError.tsx";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import getFormVersionClassNameAggregator from "../../../functions/getFormVersionClassNameAggregator.ts";
import {FormVersionType} from "../../../types/FormVersion.type.ts";

function PassengerPicker({formVersion}: {formVersion: FormVersionType}) {
    const toggleMobileOverlayPassengerPicker = useTogglePassengerPicker()
    const {
        set: setIsDropdownOpen,
        value: isDropdownOpen,
    } = useToggle()

    const {
        formErrors: {
            passengerError,
        },
        submitTimestamp,
    } = useAppSelector((state) => (state.form))

    const {v} = getFormVersionClassNameAggregator(formVersion);

    return (
        <div className={`gb--search${v()}__select gb--search${v()}__select--pax`}>
            <CustomDropdown
                field={<PassengerPickerInput/>}
                onClick={toggleMobileOverlayPassengerPicker}
                isOpen={isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
                required
            >
                <PassengerPickerOptions
                    setIsDropdownOpen={setIsDropdownOpen}
                />
            </CustomDropdown>
            <FadingError
                isError={passengerError}
                showError={submitTimestamp}
            />
            <span className={`gb--search${v()}__select--label gb--emphasize gb--emphasize-default`}>
                <Translation translationKey='search.form.passengers'/>
            </span>
        </div>
    );
}

export default PassengerPicker;
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";
import IconArrowSwitch from "../../../../../components/generic/icons/IconArrowSwitch.tsx";
import parseHTMLStrings from "../../../../../utils/string/parseHTMLStrings.ts";
import truncateName from "../../../../../utils/string/truncateName.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import Column from "../../../../../features/containers/components/Column.tsx";
import Row from "../../../../../features/containers/components/Row.tsx";
import datasetParser from "../../../../../utils/dataset/datasetParser.ts";


function RouteDescription() {
    const imageHeadingLabel = useTranslation("stationToStation.header") as string;
    const isMobile = useIsMobile()

    const {
        searchUrl: {
            from,
            to,
        },
    } = datasetParser({id: "vehicle-group-portal"})

    const getStationName = (name: string) => (
        parseHTMLStrings(isMobile ? truncateName(name, 20) : name)
    )

    const handleSwitch = async () => {
    }

    return (
        <Column className="gb--search-2-form__pre-content">
            {imageHeadingLabel.toUpperCase()}
            <Row gap center className="gb--search-2-form__pre-content_title" wrap>
                <span>
                    {getStationName(from)}
                </span>
                <button
                    type="button"
                    onClick={handleSwitch}
                    style={{cursor: "default"}}
                    className="gb--search-2-form__pre-content_icon"
                >
                    <IconArrowSwitch/>
                </button>
                <span>
                     {getStationName(to)}
                </span>
            </Row>
        </Column>
    )
}

export default RouteDescription
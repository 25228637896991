import Modal from "../../../components/generic/modal/Modal.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {useEffect, useState} from "react";
import useSubmitSearch from "../../form/hooks/useSubmitSearch.ts";
import { Column, Row} from "@getbybus/gbb-green";
import IconHourglassTimeout from "../../../components/generic/icons/IconHourglassTimeout.tsx";
import useTranslation from "../../../features/translation/hooks/useTranslation.ts";
import LoaderButton from "../../../components/generic/loaders/LoaderButton.tsx";
import useDebounce from "../../../hooks/effects/useDebounce.ts";
import {useMatches} from "react-router";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {dismissAllOverlays} from "../../../reducers/mobileSlice.ts";
import {setExpiredResults} from "../../../pages/searchResults/reducers/functions/searchResultsSlice.ts";

const ExpiredResults = () => {
    const {
        searchFinishedTimestamp
    } = useAppSelector((state) => state?.form)
    const {
        expiredResults
    } = useAppSelector((state) => state?.searchResults)
    const dispatch = useAppDispatch()
    const {refreshResults, isFetching} = useSubmitSearch()
    const title = useTranslation('booking.expired.title') as string
    const message = useTranslation('booking.expired.message') as string
    const button = useTranslation('booking.expired.button') as string
    const {debounce} = useDebounce(() => {
        dispatch(dismissAllOverlays())
        dispatch(setExpiredResults(true))
    }, 900000)
    const matches = useMatches()


    useEffect(() => {
        debounce()
        dispatch(setExpiredResults(false))
    }, [searchFinishedTimestamp])

    const handleRefresh = () => {
        refreshResults()
    }

    const shouldDisplay = () => {
        return matches[matches.length - 1]?.id === 'vehicle' || matches[matches.length - 1]?.id === 'booking-info'
    }


    return expiredResults && shouldDisplay() && (
        <Modal
            className={"gb--expired"}
            wrapperStyle={{zIndex: 1051}}
            message={
                <Column center style={{maxWidth: "400px", margin: 'auto'}} gap>
                    <IconHourglassTimeout/>
                    <h2>
                        {title}
                    </h2>
                    <p>
                        {message}
                    </p>
                    {!isFetching ? (
                        <button className={"btn btn--blue-darker"} type={'button'}
                                onClick={handleRefresh}>
                            {button}
                        </button>
                    ) : (
                        <LoaderButton style={{height: '5rem'}} className={'btn btn--blue-darker gb--search-2__select--action'}/>
                    )}
                </Column>
            }
        />
    );
};

export default ExpiredResults;
import PlaceSelectors from "../features/stations/components/PlaceSelectors.tsx";
import PassengerPicker from "../features/passengers/components/PassengerPicker.tsx";
import DateTimeField from "../features/dates/components/DateTimeField.tsx";
import HeroBackgroundMain from "../features/hero/components/HeroBackgroundMain.tsx";
import useFormMethods from "./../hooks/useFormMethods";
import useIsMobile from "../../../hooks/selector/useIsMobile.ts";
import MobilePlaceInputButton from "../features/stations/components/MobilePlaceInputButton.tsx";
import Translation from "../../../features/translation/components/Translation.tsx";
import LoaderButton from "../../../components/generic/loaders/LoaderButton.tsx";
import useSubmitSearch from "../hooks/useSubmitSearch.ts";
import ReturnDateTimeField from "../features/dates/components/ReturnDateTimeField.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {useRef} from "react";
import useRouteMatch from "../../../routing/hooks/useRouteMatch.ts";
import {allRouteIds} from "../../../routing/constants/allRouteIds.ts";
import FormWrapper from "./FormWrapper.tsx";
import FormBackground from "./FormBackground.tsx";
import SubmitButton from "../features/button/SubmitButton.tsx";
import {FormVersionType} from "../types/FormVersion.type.ts";
import getFormVersionClassNameAggregator from "../functions/getFormVersionClassNameAggregator.ts";

interface FormProps {
    formVersion: FormVersionType,
    displayDescription?: boolean
}

function SearchForm({formVersion = "1", displayDescription}: FormProps) {
    const isMobile = useIsMobile();
    const {setClassName} = useFormMethods();
    const {submitForm, isFetching} = useSubmitSearch();
    const fromInput = useRef();
    const toInput = useRef();
    const dateInput = useRef();
    const isBooking = useRouteMatch(allRouteIds.vehicle);

    const {
        fromPlace,
        toPlace,
    } = useAppSelector((state) => (state.form));

    const {v} = getFormVersionClassNameAggregator(formVersion);

    return (
        <FormWrapper formVersion={formVersion} displayDescription={displayDescription}>
            <FormBackground formVersion={formVersion}/>
            <div className={`gb--search${v()}-form`}>
                {isMobile ? (
                    <>
                        <MobilePlaceInputButton
                            formVersion={formVersion}
                            direction="departure"
                            showIsIncomplete={isBooking && !!fromPlace && !fromPlace.placeId}
                        />
                        <MobilePlaceInputButton
                            formVersion={formVersion}
                            direction="destination"
                            showIsIncomplete={isBooking && !!toPlace && !toPlace?.placeId}
                        />
                    </>
                ) : (
                    <PlaceSelectors
                        formVersion={formVersion}
                        fromRef={fromInput}
                        toRef={toInput}
                        dateRef={dateInput}
                        showIsIncomplete={isBooking}
                    />
                )}
                <div className={`gb--search${v()}__select gb--search${v()}__select--datepicker gb--row gb--row-middle`}>
                    <DateTimeField
                        formVersion={formVersion}
                        inputRef={dateInput}/>
                    <ReturnDateTimeField formVersion={formVersion}/>
                </div>
                <PassengerPicker formVersion={formVersion}/>
                <SubmitButton
                    submitForm={submitForm}
                    isFetching={isFetching}
                    formVersion={formVersion}
                />
            </div>
        </FormWrapper>
    )
}

export default SearchForm;
import SearchForm from "../../../../../layout/form/components/SearchForm.tsx";
import LocationToLocationBackgroundImage
    from "../../../../../layout/form/components/LocationToLocationBackgroundImage.tsx";
import datasetParser from "../../../../../utils/dataset/datasetParser.ts";
import {useState} from "react";

function StsPageHeader() {
    const {images} = datasetParser({id: "station-to-station"});
    const [imageFailed, setImageFailed] = useState(false)

    if (images === 'null' || imageFailed) {
        return (
            <SearchForm formVersion="1"/>
        )

    }

    return (
        <section className="gb--search-2">
            <LocationToLocationBackgroundImage imagePaths={images} setImageFailed={setImageFailed}/>
            <div className="gb--search-2-wrapper">
                <SearchForm
                    formVersion="2"
                    displayDescription
                />
            </div>
            {/*<OurValuesCta/>*/}
        </section>
    )
}

export default StsPageHeader